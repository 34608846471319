import axios from "axios";


axios.interceptors.request.use(
    config => {
      
            const token = sessionStorage.getItem('Token');
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
         
            return config;
    },
    error => {
        Promise.reject(error)
    });


axios.interceptors.response.use((response) => { // block to handle success case
    return response
}, function (error) { // block to handle error case
   
    console.log("error", error)
    if (error?.response?.data?.Message) {
        return Promise.reject(error.response.data.Message);
    }

    return Promise.reject("Unable to process request");
});

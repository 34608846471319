import { Col, Row } from "react-bootstrap";
import Header from "./Header";
import { BrowserRouter as Router } from "react-router-dom";
import SideBar from "./SideBar";
import Main from "./Main";
import { useEffect, useRef, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

export default function Home() {

  const sideNavRef = useRef(null);
  const [sidebar, setSidebar] = useState("closed");

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [])
  function handleClickOutside(event) {
    if (window.innerWidth < 768) {
      if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
        setSidebar("closed")
      }
    }
  }


  return (
    <Router>
      <Row bsPrefix={`home ${sidebar}`}>
        <Col bsPrefix="side-bar" ref={sideNavRef}>
          <SideBar setSidebar={setSidebar} sidebar={sidebar} />
        </Col>
        <Col bsPrefix="center-panel">
          <Header setSidebar={setSidebar} sidebar={sidebar} />
          <Main sidebar={sidebar}/>
        </Col>
        <ToastContainer
          autoClose={process.env.TOAST_AUTO_CLOSE}
          className="toast-container"
          hideProgressBar={true}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
          theme="colored"
          icon={false}
        />
      </Row>
    </Router>
  )
}
import axios from "axios"

const URL = process.env.REACT_APP_POC_MASTER_API
const companyCode = sessionStorage.getItem("SelectedCompanyCode");
const userId= sessionStorage.getItem("UserId");
console.log(" userId ", userId);
export async function authenticate(email) {
  let url = URL + "/authenticate/" + encodeURIComponent(email);
  console.log(" url ", url);
  let response = await axios.get(url);
  return response.data;
}

export async function getAllUsers(userCompanyCode) {
  console.log(" userCompanyCode ", userCompanyCode)
  let url = URL+"/getallusers";
  console.log(" url ", url);
  let userIdData=sessionStorage.getItem("UserId");
  let companyCodeData=sessionStorage.getItem("SelectedCompanyCode");  
  let response = await axios.get(url, {
    headers: {
      "companyCode": companyCodeData,
      "userId": userIdData,
    }
  });
  console.log(" response ", response.data);
  return response.data;
}

export async function getCompanyData() {
  let url = URL+"/companydata";
  let response = await axios.get(url);
  console.log(" response companydata", response.data);
  return response.data;
}

export async function getUserCompanyData() {

  let url = URL+"/usercompanydata";
  console.log(" getUserCompanyData url ", url,  " userId ", userId)
  let response = await axios.get(url, {
    headers: {
      "userId": userId,
    }
  });
  return response.data;
}

export async function getHrisUsers() {
  let url = URL + "/hris";
  let response = await axios.get(url, {
    headers: {     
      "companyCode": companyCode,
    }
  });
  console.log(" response getHrisUsers", response.data);
  return response.data;

}

export const addNewUser = async (user) => {
  let userIdData=sessionStorage.getItem("UserId");
  let companyCodeData=sessionStorage.getItem("SelectedCompanyCode");  
  let url = `${URL}/adduser`;
   const response = await axios.post(url, user, {
    headers: {
      "companyCode": companyCodeData,
      "userId": userIdData,
    }
  }); 
  return response.data;
};

export const updateUser = async (user) => {
  let userIdData=sessionStorage.getItem("UserId");
  let companyCodeData=sessionStorage.getItem("SelectedCompanyCode"); 
  let url =`${URL}/updateuser`;
  console.log(" url ", url);
  const response = await axios.post(url, user,{
    headers: {
      "companyCode": companyCodeData,
      "userId": userIdData,
    }
  })
  return response.data;
};

export const deleteUser = async (user) => {
  let userIdData=sessionStorage.getItem("UserId");
  let companyCodeData=sessionStorage.getItem("SelectedCompanyCode");  
  let url =`${URL}/deleteuser/${encodeURIComponent(user.user_id)}`;
  console.log(" url ", url);
  const response = await axios.post(url,user,{
    headers: {
      "companyCode": companyCodeData,
      "userId": userIdData,
    }
  })
  return response.data;
};

export const getUserRoleMenuMapping = async (role_code, selectedCompany) => {
  console.log(" under getUserRoleMenuMapping user Service ", " role_code ",  role_code, " companyCode ", selectedCompany)
  let url =`${URL}/getuserrolemenumapping/${encodeURIComponent(role_code)}`;
  console.log(" url ", url);
  const response = await axios.get(url, {
    headers: {
      "companyCode": selectedCompany,
      "userId": userId,
    }
  })
  console.log(" response getUserRoleMenuMapping ", response);
  return response.data;
};


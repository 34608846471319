
let constants = {
  CONTAINS: "Contains",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  EDIT: "Edit",
  ADD: "Add",
  MENUS: {    
    "Admin": {
      "Menus": [
        { "SubMenu": "Sample", "Path": "/sample" },
        { "SubMenu": "Maintain User", "Path": "/usermaster" }],
    }    
  },
  CONFIRMATION_BOX_CONFIG: {
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    reverseButtons: true,
    showCloseButton: true,
    customClass: "swal-confirmation",
  },

  INFORMATION_BOX_CONFIG: {
    showCancelButton: false,
    showConfirmButton: false,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    reverseButtons: true,
    showCloseButton: true,
    customClass: "swal-confirmation-Information",
  },
  

};



module.exports = Object.freeze(constants);

import constants from "./constants";
export function userlogo(name) {
  let userLogo = name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase();
  return userLogo === "" ? "AB" : userLogo;
}

export const RenderIf = ({ children, isTrue }) => {
  return isTrue ? children : null;
};

export function formatListEquipNo(data) {
  let seperated = "";
  for (let obj of data) {
    seperated = seperated + "," + obj.EquipmentNumber;
  }
  seperated = seperated.slice(1);
  return seperated;
}

export function formatListProcedureRef(data) {
  let seperated = "";
  for (let obj of data) {
    seperated =
      seperated +
      "," +
      obj.ProcedureReference +
      " " +
      obj.RevisionNo +
      " " +
      obj.RevisionDate +
      " " +
      obj.ApplicableIn;
  }
  seperated = seperated.slice(1);
  return seperated;
}

export function updateOfferStatus(value) {
  let status = "";
  if (value === constants.PENDING_FOR_OFFER) {
    status = "Offer Not Created";
  } else {
    status = "Offer Created";
  }
  return status;
}
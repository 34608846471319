import { Nav, Collapse, Image, Row } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import NavItem from "../common/NavItem";
import React, { useEffect, useState } from "react";
import godrejLogo from "../../assets/godrej.svg";
import { userlogo } from "../../helpers/formatter";
import logoutarrow from "../../assets/logoutarrow.svg"
import { useMsal } from "@azure/msal-react";
import dashboard from "../../assets/dashboard_logo_new.svg"
import transaction from "../../assets/transaction.svg"
import adminIcon from "../../assets/admin.svg"
import maintenance from "../../assets/maintenance.svg"
import reportIcon from "../../assets/report.svg"

export default function SideBar(props) {
  let icons = { "Transaction": transaction, "Admin": adminIcon, "Masters": maintenance, "Report": reportIcon }
  const [open, setOpen] = useState({});
  const [authorizedSessions, setAuthorizedSessions] = useState({});

  const { instance } = useMsal();

  function handleLogout(instance) {
    sessionStorage.clear()
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }

  useEffect(() => {
    if (props.sidebar == "closed") {
      setOpen({})
    }
  }, [props.sidebar])

  const setMenu = () => { 
    try{
    let authorizedSessionsTempArr = []
    let authorizedSessionsTemp = {}
    let authorizedSessions = sessionStorage.getItem("AuthorizedSessions"); 
    console.log(" authorizedSessions ", authorizedSessions);  
    if (authorizedSessions && authorizedSessions != "") {
      authorizedSessions = JSON.parse(authorizedSessions);
    }  
    for (const element of authorizedSessions) {
      authorizedSessionsTempArr.push(element["json_build_object"])
    }
    authorizedSessionsTempArr.forEach(obj => {
      const key = Object.keys(obj)[0];
    console.log(" key ", key);
    console.log(" obj[key] ", obj[key]);
      const allFalse = obj[key].every(record => !record.read && !record.write );
      console.log("authorizedSessionsTempArr key" , key, " allFalse ", allFalse)
      if(!allFalse){
      authorizedSessionsTemp[key] = obj[key]
      }
    });   
    console.log(" authorizedSessionsTemp ", authorizedSessionsTemp);
    setAuthorizedSessions(authorizedSessionsTemp);
    
  } catch (error) {
       console.log(error)
     }
  }
  useEffect(() => {
    setMenu();
  }, [])
{/* <img src={logo} alt="PocrrLogo" className="head-logo me-5 my-1" /> */}
  return (
    <>
      <div className="sidebar-head">
       {/* {props.sidebar==='open'&& <span className="head-logo">POC-Revenue Recognition</span>} */}
        
          {/* changes by bharshal */}
        { props.sidebar==='open'&&<div className="logoname"> 
            <div className="first">POC Revenue Recognition</div>
            
            </div>} 
            <List className="head-toggle" onClick={() => { props.setSidebar(props.sidebar === "closed" ? "open" : "closed") }}></List>  
        <div className="user-section">
          <div className="user-logo">
            {userlogo(sessionStorage.getItem("UserId"))}
          </div>
          <div className="user-info">
            <Row>
              <span className="user-name">{sessionStorage.getItem("UserId")}</span>
            </Row>
            <Row>
              <span className="user-email">{sessionStorage.getItem("Email")} </span>
            </Row>
          </div>
          <Image src={logoutarrow} onClick={(e) => handleLogout(instance)}></Image>
        </div>
      </div>
      <div className={`sidebar-body ${props.sidebar}`}>
        <Nav bsPrefix="nav-list" defaultActiveKey={"Dashboard"}>
          <NavItem menuType={"parent"} menu={"Dashboard"} path={"/"} icon={dashboard} setSidebar={props.setSidebar}></NavItem>
          {Object.keys(authorizedSessions).map((parentMenu, index) => {
            return <React.Fragment key={index}>
              {authorizedSessions[parentMenu]?.length > 0 ? <React.Fragment>
                <NavItem menuType={"parent"} menu={parentMenu} setOpen={setOpen} open={open} ariaControls={parentMenu} icon={icons[parentMenu]} setSidebar={props.setSidebar}></NavItem>
                <Collapse in={open[parentMenu]}>
                  <div id={parentMenu}>
                    {authorizedSessions[parentMenu].map((item, index) => {
                      return <React.Fragment key={index} > {!item.Menu && (item.read || item.write) && <NavItem menuType={"menu"} menu={item.submenu} path={item.path} setSidebar={props.setSidebar} ></NavItem>}</React.Fragment>
                    })}
                  </div></Collapse>
              </React.Fragment>
                : <></>
              }
            </React.Fragment>
          })}

        </Nav>
        <div className="footer">
          <Image className="footer-logo mt-auto" src={godrejLogo} />
        </div>
      </div>
    </>
  );
}

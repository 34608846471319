import React, { useEffect, useState } from 'react';
import './App.css';
import "./helpers/interceptor"

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./auth-config";
import Home from './components/base/Home';
import Login from './components/base/Login';
import CompanySelectionModal from './components/base/CompanyModel';
import { authenticate, getUserRoleMenuMapping } from './services/user-service';

function App() {

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [validUser, setValidUser] = useState();
  const [userCompany, setUserCompany] = useState("")
  const [idToken, setIdToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  let userCompanyData;
  console.log("changedby_Sagar_SonarReport_28112024")

  useEffect(() => {
    if (isAuthenticated) {
      RequestProfileData();
    }
    if (idToken && idToken !== "") {
      sessionStorage.setItem("Token", idToken);
      getUserData();
    }
  }, [isAuthenticated, idToken]); // eslint-disable-line react-hooks/exhaustive-deps
  function RequestProfileData() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setIdToken(response.idToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setIdToken(response.idToken);
        });
      });
  }

  async function getUserData() {

    console.log("changedby_Sagar_getUserData_in")
    console.log("Inside Accounts Data", accounts[0]);
    const email = accounts[0]?.username;
    const userid = email.split("@")[0];
    console.log("userid", userid, "email", email, accounts[0]);
  
    try {
      const result = await authenticate(userid);
      console.log("result", result);
  
      if (result?.UserData[0]) {
        const userData = result.UserData[0];
        console.log("userData", userData);
  
        sessionStorage.setItem("Role", userData.user_role || "");
        sessionStorage.setItem("Token", result.Token);
        sessionStorage.setItem("Email", userData.user_email || "");
        sessionStorage.setItem("UserId", userData.user_id || "");
        const companyData = userData.company_code ? userData.company_code.split(",") : [];
        sessionStorage.setItem("CompanyData", companyData);
        console.log("userCompanyData", companyData);
  
        if (companyData.length > 0) {
          handleCompanySelection(companyData);
          setValidUser(true);
        } else {
          handleError("Company code is not mapped to your Id");
        }
      } else {
        handleError("Unable to process the request.");
      }
    } catch (err) {
      console.log("Error in fetching details", err);
      setValidUser(false);
      setErrorMessage(err);
      sessionStorage.clear();
    }
  }
  
  function handleCompanySelection(companyData) {
    const userselection = sessionStorage.getItem("SelectedCompanyCode");
    if (companyData.length === 1) {
      handleSubmit(companyData[0]);
    } else {
      if (!userselection) {
        setShow(true);
      } else {
        setShow(false);
        handleSubmit(userselection);
      }
    }
  }
  
  function handleError(message) {
    setErrorMessage(message);
    handleClose();
  }


  const handleClose = () => {
    setShow(false);
    setValidUser(false);

  };
  const handleSubmit = async (selectedCompany) => {
    if (selectedCompany) {
      console.log(" selectedCompany ", selectedCompany)
      let selected_company_code = selectedCompany.split("-")[0]
      sessionStorage.setItem("SelectedCompanyCode", selected_company_code);
      setUserCompany(selected_company_code);
      let result = await getUserRoleMenuMapping(sessionStorage.getItem("Role"), selected_company_code).catch((err) => {
        console.log("Error in fetching details", err)
        setValidUser(false);
        setErrorMessage(err)
        sessionStorage.clear()
      }
      );
      console.log(" result getUserRoleMenuMapping", result)
      console.log(" result getUserRoleMenuMapping", result[0])
      console.log(result[0], "***********", result, "*********************** getUserRoleMenuMapping result getUserRoleMenuMapping**************** ", " ", JSON.stringify(result));
           if (result?.[0] !== "") {
        console.log("Setting AuthorizedSessions ",)
        sessionStorage.setItem("AuthorizedSessions", JSON.stringify(result))
        console.log(sessionStorage.getItem("AuthorizedSessions"));
      }
      else {
        sessionStorage.setItem("AuthorizedSessions", [])
      }
      setShow(false);
    }
    else {
      setValidUser(false);
    }
  }

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        {validUser === true && show === true && (
          <CompanySelectionModal
            handleSubmit={handleSubmit}
            show={show}
            handleClose={handleClose}
            userCompanyData={userCompanyData}
          />
        )

        }
        {validUser === true && show === false && (
          <Home userCompany={userCompany} />
        )
        }
        {validUser === false && (
          <Login errorMessage={errorMessage} openModal={false} userCompanyData={[]} />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
}

export default App;
